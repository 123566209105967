import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';


class Header extends Component {
  render () {
    return (
      <div className="horizontal-menu">
        <nav className="navbar top-navbar default-layout-navbar col-lg-12 col-12 p-0 d-flex flex-row">
          <div className="container">
            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
              {/* <Link className="navbar-brand brand-logo" to="/"><img src={require('../../assets/images/logo-white.svg')} alt="logo" /></Link>
              <Link className="navbar-brand brand-logo-mini" to="/"><img src={require('../../assets/images/logo-mini.svg')} alt="logo" /></Link> */}
            </div>
            <div className="navbar-menu-wrapper d-flex align-items-stretch justify-content-end">
              <ul className="navbar-nav mr-lg-2 ">
                <div className="preview-thumbnail">
                  <img src="https://via.placeholder.com/36x36"  alt="profile" />
                </div>
              </ul>
              <ul className="navbar-nav navbar-nav-right">
                <li className="nav-item nav-profile">
                  <Dropdown alignRight>
                    <Dropdown.Toggle className="nav-link count-indicator">
                      <img src="https://via.placeholder.com/40x40" alt="profile"/>
                    </Dropdown.Toggle>
                  </Dropdown>
                </li>
              </ul>
              <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleBottomMenu}>
                <span className="ti-menu"></span>
              </button>
            </div>
          </div>
        </nav>
        <nav className="bottom-navbar">
          <div className="container">
            <ul className="nav page-navigation">
              <li className={ this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item' }>
                <Link className="nav-link" to="/home">
                  <i className="ti-home menu-icon"></i>
                  <span className="menu-title">Cuadro de Mando</span>
                </Link>
              </li>
              <li className={ this.isPathActive('/widgets') ? 'nav-item active' : 'nav-item' }>
                <Link className="nav-link" to="/prestadores">
                  <i className="ti-settings menu-icon"></i>
                  <span className="menu-title">Prestadores</span>
                </Link>
              </li>
              
              
              <li className={ this.isPathActive('/apps') ? 'nav-item active' : 'nav-item' }>
                <div className="nav-link">
                  <i className="ti-package menu-icon"></i>
                  <span className="menu-title">Pendientes</span>
                  <i className="menu-arrow"></i></div>
                <div className="submenu">
                  <ul className="submenu-item">
                    <li className="nav-item"> <Link className={ this.isPathActive('/apps/kanban-board') ? 'nav-link active' : 'nav-link' } to="/lenderPending">Prestador</Link></li>
                    <li className="nav-item"> <Link className={ this.isPathActive('/apps/todo-list') ? 'nav-link active' : 'nav-link' } to="/apps/todo-list">Gestor</Link></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }

  toggleBottomMenu() {
    document.querySelector('.bottom-navbar').classList.toggle('header-toggled');
  }

  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }

  componentDidMount() {
    // Horizontal menu fixed when scrolling
    let navbar = document.querySelector('.horizontal-menu');
    let body = document.querySelector('body');
    if ( navbar ) {
      window.addEventListener('scroll', function() { 
        if (window.scrollY >= 70) { 
          navbar.classList.add('fixed-on-scroll');
          body.classList.add('horizontal-menu-fixed-on-scroll');
         }
        else { 
          navbar.classList.remove('fixed-on-scroll');
          body.classList.remove('horizontal-menu-fixed-on-scroll');
         }
      });
    }

    // Horizontal menu navigation in mobile menu on click
    let navItemClicked = document.querySelectorAll('.horizontal-menu .page-navigation >.nav-item');
    navItemClicked.forEach(function (el) {
      el.addEventListener('click', function () {
        var result = [],
          node = this.parentNode.firstChild;
        while (node) {
          if (node !== this)
            result.push(node);
          node = node.nextElementSibling || node.nextSibling;
        }
        result.forEach( (el) => el.classList.remove('show-submenu') )
        this.classList.toggle('show-submenu');
      });
    })
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

}

export default withRouter(Header);
