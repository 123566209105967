import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./page/Home";
import Prestadores from "./page/prestadores/Prestadores";
import DetailLender from "./page/prestadores/DetailLender";
import LenderBien from './page/prestadores/LenderBien';
import LenderPending from './page/prestadores/LenderPending';
import LenderMal from './page/prestadores/LenderMal';
import LenderProceso from './page/prestadores/LenderProceso';

export const AdminRouter = () => {

    return (

        <>
            <Switch>
                <Route exact path="/home" component={Home} />
                <Route exact path="/prestadores" component={Prestadores} />
                <Route exact path="/detallePrestadores/:id" component={DetailLender} />
                <Route exact path="/lenderBien" component={LenderBien} />
                <Route exact path="/lenderMal" component={LenderMal} />
                <Route exact path="/lenderProceso" component={LenderProceso} />
                <Route exact path="/lenderPending" component={LenderPending} />
            </Switch>
        </>
    );
}