import settingHTTP from "./settingHTTP";

const  LenderProcesoService = {

  async getLenderProceso() {
    try {
      const response = await settingHTTP.get("lenders/");
      if (response.status === 200) {
        // console.log(response.data.data.results)
        return response.data.data.results;
      }
    } catch (e) {
      return e;
    }
  },

  async getLendersById(Id) {
    try {
      const ruta = "lenders/" +Id +"/";
      console.log("aqui la ruta",ruta); 
      const response = await settingHTTP.get(ruta);
     
      if (response.status === 200) {
         //console.log(response.data.data.results)
        return response;
      }
    } catch (e) {
      return e;
    }
  },

  async getStateByLenderNivel(lender , level) {
    try {
      const ruta = "lenderindicators/?lender="+lender+"&level=" +level;
      const response = await settingHTTP.get(ruta);
      if (response.status === 200) {
         //console.log(response.data.data.results)
        return response;
      }
    } catch (e) {
      return e;
    }
  },

};

export default LenderProcesoService;