
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, Row } from "react-bootstrap";
import ListTablePrestadores from "../../Components/Lenders/ListTablePrestadores";
import PrestadoresService from "../../services/Prestadores.API/Prestadores";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
const { SearchBar } = Search;

var FileSaver = require('file-saver');


function Prestadores(){

  const [prestadores, setPrestadores] = useState([]);
  const defaultSorted = [{
    dataField: 'id',
    order: 'desc'
  }];


  const columns = [

    { dataField: "name", text: "Prestador" },
    { dataField: "type_association.name", text: "Tipo" },
    { dataField: "manager_name", text: "Encargado" },
    { dataField: "ubigeo.name", text: "Ubicación" },
    {
      dataField: "Button",
      text: "Detalle",
      formatter: (rowContent, row) => {
        return (
          <div className="d-flex justify-space-between pl-4">
            <Link className="card text-decoration-none" to={"/detallePrestadores/" + row.id} >
              <div
                style={{ cursor: "pointer" }}
              >
                <i
                  className="fa  fa-external-link fa-2x text-primary"
                  placeholder="Ver Detalle"
                  title="Detalle"
                ></i>
              </div>
            </Link>
          </div>
  
        );
      },
    },
  ];
  
  const getData = async () => {
    try {

      const responsePrestador = await PrestadoresService.getAllPrestadores();
      setPrestadores(responsePrestador)

    } catch (e) {
      console.error("error al obtener la data de IdeaDetails", e.error)
    }
  }

  useEffect(()=>{
    getData();
  },[])

  return (
    <div> 
      <Row>
        <Card className="col-lg-12">
          <Card.Body>
            <div className="row mb-4 justify-content-between">
              <div className = "col-lg-3 d-none d-sm-none d-md-block">
                <Link className="float-left mr-4" to={"/home"}>
                  <i className=" mdi mdi-arrow-left-drop-circle-outline ml-2  text-info" style={{fontSize:30}}></i>
                </Link>
                <h4 className="card-title float-left mt-2">Lista de Prestadores</h4>
              </div>
              <div className = "col-lg-8 pr-0 pl-0 col-ms-12 mr-3">
                {/* <SearchCompromisos className = "float-left" onChangeBusqueda = {onChangeBusqueda}/> */}                </div>
              </div>
              <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={ prestadores }
                      columns={ columns }
                      search
                    >
                      {
                        props => (
                          <div>
                            <div className="d-flex align-items-center">
                              <p className="mb-2 mr-2">Busqueda: </p>
                              <SearchBar { ...props.searchProps }  placeholder = {"buscar"}/>
                            </div>
                            <BootstrapTable
                              defaultSorted={ defaultSorted }
                              pagination={ paginationFactory() }
                              { ...props.baseProps }
                              wrapperClasses="orders-table table-responsive border-left border-right"
                            />
                          </div>
                        )
                      }
              </ToolkitProvider>
            {/* <ListTablePrestadores
               data={prestadores}
              // page={paginacion.page}
              // sizePerPage={paginacion.sizePerPage}
              // totalSize={compromisosPage.Total}
              // onTableChange={handleTableChange}
              // verificacion = {0}
            /> */}
          </Card.Body>
        </Card>
      </Row>
    </div>
  );
  
};
export default Prestadores;