import React, {Component} from "react";
import GoogleMaps from "simple-react-google-maps"

export default class Maps extends Component{
    render(){
        return(
            <GoogleMaps
            apiKey={"AIzaSyAIoaqD6zupornIMbdYcAfDaTSHjAjFWJ4"}
            style={{height: "650px", width: "100%"}}
            zoom={9}
            center={{lat: -7.157072 , lng:-78.517499}}
            markers={[
                {lat: -7.1641227 , lng:-78.4561634},
                {lat: -7.1641238 , lng:-78.4561627},
                {lat: -7.1668103 , lng:-78.5023636},
                {lat: -7.1683196 , lng:-78.5030117}
            ]} 
            />
        )
    }
}

