import React, { useState } from "react";
import { Row, Modal, Form, Button, ProgressBar,  InputGroup,FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";

const Swal = require("sweetalert2");

const ListLenders = ({data, page, sizePerPage, totalSize, onTableChange,verificacion }) => {
  console.log("Muestramelo bb", data)
  var table = [
      {
        id: 'XN-1',
        Prestador: 'Jass Sunas',
        Estado: 'Bien',
        Encargado: 'Juan Roseles',
      },
      {
          id: 'XN-2',
          Prestador: 'Jass Sunas',
          Estado: 'Bien',
          Encargado: 'Juan Roseles',
      },
      {
          id: 'XN-3',
          Prestador: 'Jass Sunas',
          Estado: 'Bien',
          Encargado: 'Juan Roseles',
      },
  ]

  const columns = [

  { dataField: "name", text: "Prestador" },
  { dataField: "ubigeo", text: "Estado" },
  { dataField: "type_association", text: "Encargado" },
  { dataField: 'action', text: 'Action', sort: false,
    formatter: () => {
      return (
        <div>
          <button className="btn btn-light">
            <i className="ti-eye text-primary"></i>View
          </button>
        </div>
      );
    }
  },
  {
    dataField: "Button",
    text: "Detalle",
    formatter: (rowContent, row) => {
      return (
        <div className="d-flex justify-space-between pl-4">
          <Link className="card text-decoration-none" to={"/compromisos/detalles/" + row.Id} >
            <div
              style={{ cursor: "pointer" }}
            >
              <i
                className="fa  fa-external-link fa-2x text-primary"
                placeholder="Ver Detalle"
                title="Detalle"
              ></i>
            </div>
          </Link>
        </div>

      );
    },
  },
];

return (
  <Row>
    <div className="col-12 mt-4">
      <ToolkitProvider keyField="Id" bootstrap4 data={data} columns={columns} search>
        {(props) => (
          <div>
            <BootstrapTable
              remote
              pagination={paginationFactory({ page, sizePerPage, totalSize })}
              onTableChange = {onTableChange}
              {...props.baseProps}
              wrapperClasses="orders-table table-responsive border-left border-right"
              noDataIndication="Lista Vacía"
            />
          </div>
        )}
      </ToolkitProvider>
    </div>
  </Row>
);
};
export default ListLenders;
