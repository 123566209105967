import React, { useState } from "react"
import { Redirect, Route } from "react-router-dom";

const PrivateRouter = ({ component: Component, ...rest }) => {
    const [jwt, setJWT] = useState(() => window.localStorage.getItem('jwt'))
    return (<Route
        {...rest}
        render={((props) => jwt ? <Component {...props} /> : <Redirect to='/login' />)}
    />);

}


export default PrivateRouter;