import axios from "axios";

const instance = axios.create({
  baseURL: "https://sunassback.smonitocaj.com/api/",
});

var token = window.localStorage.getItem("jwt");

instance.defaults.headers.common["Authorization"] = `Token ${token}`;

export default instance;
