import logo from './../logo.svg';
import { withRouter } from 'react-router-dom';
import React , { Component}  from 'react';
import HeaderDashboard from './Components/Shared/HeaderDashboard';
import Footer from './Components/Shared/Footer';
import './App.scss';
import AppRoutes from './AppRoutes';

import { UserContextProvider } from "./Context/UserContext";


class App extends Component {

  
  state = {}
  componentDidMount() {
    this.onRouteChanged();
  }
  render () {  
    let footerComponent = !this.state.isFullPageLayout ? <Footer/> : '';
    let Headers = !this.state.isFullPageLayout ? <HeaderDashboard/> : '';
    return (  
      <UserContextProvider>
        <div className="container-scroller">
          { Headers }
          <div className="container-fluid page-body-wrapper">
            <div className="main-panel pt-0">
              <div className="content-wrapper">
                <AppRoutes/>
              </div>
              { footerComponent }
            </div>
          </div>
        </div>
      </UserContextProvider> 
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    console.log("ROUTE CHANGED");
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/user-pages/lockscreen','/login', '/error-pages/error-404', '/error-pages/error-500'];
    for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }
}
export default withRouter(App);
