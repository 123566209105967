
import React, { useState, useEffect, Component } from "react";
import { Link, useHistory } from "react-router-dom";
import {Line, Bar, Doughnut, Chart} from 'react-chartjs-2';
import { Card, Row, Form, Tabs, Tab, ProgressBar } from "react-bootstrap";
import ListTablePrestadores from "../../Components/Lenders/ListTablePrestadores";
import PrestadoresService from "../../services/Prestadores.API/Prestadores";

var FileSaver = require('file-saver');


function DetailLender({ match }){

  const [prestador, setPrestador] = useState([]);
  const [ubigeo, setUbigeo] = useState("");
  const [tipo, setTipo] = useState("");
  const [indicadorLB, setIndicadorLB] = useState([]);
  const [indicadorIntervencion, setIndicadorIntervencion] = useState([]);
  const [indicadorS, setIndicadorS] = useState([]);
  const compromisoId = match.params.id;

  const [graficoLineaBase,setGraficoLineaBase] = useState([]);
  const [graficoIntervencion,setGraficoIntervencion] = useState([]);
  const [graficoSeguimiento,setGraficoSeguimiento] = useState([]);

  var LineaBase = {
    datasets: [{
      data: graficoLineaBase,
      backgroundColor: [
        "#00d655", "#ffc100", "#FF0000", "#9C9C9C"
      ],
      borderColor: "rgba(0,0,0,0)"
    }],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: [
      'BIEN',
      'EN PROCESO',
      'MAL',
      'SIN REGISTRAR',
    ],
    text: '90',
  };
  var Intervencion = {
    datasets: [{
      data: graficoIntervencion,
      backgroundColor: [
        "#00d655", "#ffc100", "#FF0000", "#9C9C9C"
      ],
      borderColor: "rgba(0,0,0,0)"
    }],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: [
      'BIEN',
      'EN PROCESO',
      'MAL',
      'SIN REGISTRAR',
    ],
    text: '90',
  };
  var Seguimiento = {
    datasets: [{
      data: graficoSeguimiento,
      backgroundColor: [
        "#00d655", "#ffc100", "#FF0000", "#9C9C9C"
      ],
      borderColor: "rgba(0,0,0,0)"
    }],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: [
      'BIEN',
      'EN PROCESO',
      'MAL',
      'SIN REGISTRAR',
    ],
    text: '90',
  };

  var GraficoOptions = {
    responsive: true,
    maintainAspectRatio: true,
    segmentShowStroke: false,
    cutoutPercentage: 0,
    elements: {
      arc: {
          borderWidth: 4
      }
    },      
    legend: {
      display: false
    },
    tooltips: {
      enabled: true
    },
  };

  const getDataGraficos = async () => {
    try { 
      const responsePrestadorGraficos = await PrestadoresService.getGraficosByLender(compromisoId);
      if(responsePrestadorGraficos.data.ok){
        setGraficoLineaBase(responsePrestadorGraficos.data.data.line_base);
        setGraficoIntervencion(responsePrestadorGraficos.data.data.interventions)
        setGraficoSeguimiento(responsePrestadorGraficos.data.data.monitoring)
      
      }
    }catch(e){
      console.error("error al obtener la data de Gráficos", e.error)
    }
  }

  const getData = async () => {
    try {
      const responsePrestador = await PrestadoresService.getLendersById(compromisoId);
      console.log("Prestador aqui respuesta pe",responsePrestador)
      if(responsePrestador.data.ok){
        setPrestador(responsePrestador.data.data);
        setUbigeo(responsePrestador.data.data.ubigeo.name);
        setTipo(responsePrestador.data.data.type_association.name);
      }
    } catch (e) {
      console.error("error al obtener la data de IdeaDetails", e.error)
    }
  }

  const getIndicadoresLineaBase = async () => {
    try {
      const responsePrestador = await PrestadoresService.getStateByLenderNivel(compromisoId, 7);
      if(responsePrestador.data.ok){
        setIndicadorLB(responsePrestador.data.data.results);
      }
    } catch (e) {
      console.error("error al obtener la data de IdeaDetails", e.error)
    }
  }

  const getIndicadoresIntervencion = async () => {
    try {
      const responsePrestador = await PrestadoresService.getStateByLenderNivel(compromisoId, 8);
      if(responsePrestador.data.ok){
        setIndicadorIntervencion(responsePrestador.data.data.results);
        console.log("si se pudo lograr xd")
      }
    } catch (e) {
      console.error("error al obtener la data de IdeaDetails", e.error)
    }
  }

  const getIndicadoresM = async () => {
    try {
      const responsePrestador = await PrestadoresService.getStateByLenderNivel(compromisoId, 9);
      if(responsePrestador.data.ok){
        setIndicadorS(responsePrestador.data.data.results);
      }
    } catch (e) {
      console.error("error al obtener la data de IdeaDetails", e.error)
    }
  }

  useEffect(()=>{
    getDataGraficos();
    getData();
    getIndicadoresLineaBase();
    getIndicadoresIntervencion();
    getIndicadoresM();
  },[])

  return (
    <div> 
      <div className = "row">
        <div className="col-md-12 stretch-card row">
          <Card className="col-lg-12">
            <Card.Body>
              <div className="row mb-4 justify-content-between">
                  <div className = "col-lg-12 d-none d-sm-none d-md-block">
                    <Link className="float-left mr-4" to={"/prestadores"}>
                      <i className=" mdi mdi-arrow-left-drop-circle-outline ml-2  text-info" style={{fontSize:30}}></i>
                    </Link>
                    <h2 className="card-title float-left mt-2">Detalle del Prestador</h2>
                    <br></br>
                  </div>
                  <div className = "col-lg-12">
                    <form className="forms-sample">
                      <br></br>
                      <Form.Group>
                        <h5 htmlFor="exampleInputUsername1">Prestador</h5>
                        <Form.Control disabled={true}  type="text" id="exampleInputUsername1" placeholder="Username" value = {prestador.name} />
                      </Form.Group>
                      <div className="row">
                        <div className="col-lg-6">
                          <Form.Group>
                          <h5 htmlFor="exampleInputUsername1">Tipo de Organización</h5>
                            <Form.Control disabled={true}  type="text" id="exampleInputUsername1" placeholder="Username" value = {tipo}/>
                          </Form.Group>
                        </div>
                        <div className="col-lg-4">
                          <Form.Group>
                          <h5 htmlFor="exampleInputUsername1">Ubicación</h5>
                            <Form.Control disabled={true}  type="text" id="exampleInputUsername1" placeholder="Username" value = {ubigeo}/> 
                          </Form.Group>
                        </div>
                        <div className="col-lg-2">
                          <Form.Group>
                          <h5 htmlFor="exampleInputUsername1">Asociados</h5>
                            <Form.Control disabled={true}  type="text" id="exampleInputUsername1" placeholder="Username" value = {prestador.number_association}/>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        
                        <div className="col-lg-6">
                          <Form.Group>
                          <h5 htmlFor="exampleInputUsername1">Directivo</h5>
                            <Form.Control disabled={true}  type="text"  id="exampleInputUsername1" placeholder="Username" value = {prestador.manager_name}/> 
                          </Form.Group>
                        </div>
                        <div className="col-lg-3">
                          <Form.Group>
                          <h5 htmlFor="exampleInputUsername1">Correo</h5>
                            <Form.Control disabled={true}  type="text" id="exampleInputUsername1" placeholder="Username" value = {prestador.manager_email}/> 
                          </Form.Group>
                        </div>
                        <div className="col-lg-3">
                          <Form.Group>
                          <h5 htmlFor="exampleInputUsername1">Telefono</h5>
                            <Form.Control disabled={true}  type="text" id="exampleInputUsername1" placeholder="Username" value = {prestador.manager_phone}/> 
                          </Form.Group>
                        </div>
                      </div>
                    </form>
                  </div>
              </div>
              
            </Card.Body>
          </Card>
          <div className="card" style={{marginTop:20}}>
                <div className="card-body">
                  <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                    <Tab eventKey="home" title="LINEA BASE" className="test-tab">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12 col-xl-12 col-12">
                            <div className="row">
                              <div className="col-md-7">
                                <div className="table-responsive mb-3 mb-md-0">
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th className= "justify-content-center">Indicador</th>
                                        <th className= "justify-content-center" >Fecha</th>
                                        <th className= "justify-content-center" >Estado</th>
                                        <th className= "justify-content-center" >Observación</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {indicadorLB.map((item,index) =>(
                                        <tr>
                                        <td>{item.indicator.name}</td>
                                        <td>{item.modification_date}</td>
                                        {item.state ? (
                                          <td>{item.state.name}</td> 
                                        ):(
                                          <td></td> 
                                        )
                                        }
                                         <td>{item.first_observation == null ? "" : item.first_observation}</td>
                                        
                                      </tr>
                                      ))}
                                    </tbody>
                                    
                                  </table>
                                </div>
                              </div>
                              <div className="col-md-5 mt-3">
                                <Doughnut data={LineaBase} options = {GraficoOptions} />
                                <div className="detailed-reports-chart-legend north-america-legend">
                                  <div className="item d-flex justify-content-between mx-4 mx-xl-5 mt-3">
                                    <div className="d-flex align-items-center">
                                      <p className="mb-0">BIEN</p>
                                    </div>
                                    <p className="mb-0">{graficoLineaBase[0]}</p>
                                  </div>
                                  <div className="item d-flex justify-content-between mx-4 mx-xl-5 mt-3">
                                    <div className="d-flex align-items-center">
                                      <p className="mb-0">EN PROCESO</p>
                                    </div>
                                    <p className="mb-0">{graficoLineaBase[1]}</p>
                                  </div>
                                  <div className="item d-flex justify-content-between mx-4 mx-xl-5 mt-3">
                                    <div className="d-flex align-items-center">
                                      <p className="mb-0">MAL</p>
                                    </div>
                                    <p className="mb-0">{graficoLineaBase[2]}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="profile" title="INTERVENCIÓN" >
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12 col-xl-12 col-12">
                            <div className="row">
                              <div className="col-md-7">
                                <div className="table-responsive mb-3 mb-md-0">
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th className= "justify-content-center">Indicador</th>
                                        <th className= "justify-content-center" >Fecha</th>
                                        <th className= "justify-content-center" >Estado</th>
                                        <th className= "justify-content-center" >Detalle</th>
                                        <th className= "justify-content-center" >Observación</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {indicadorIntervencion.map((item,index) =>(
                                        <tr>
                                        <td>{item.indicator.name}</td>
                                        <td>{item.modification_date}</td>
                                        {item.state ? (
                                          <td>{item.state.name}</td> 
                                        ):(
                                          <td></td> 
                                        )
                                        }
                                        {item.state_detail ? (
                                          <td>{item.state_detail.name}</td> 
                                        ):(
                                          <td></td> 
                                        )
                                        }
                                        <td>{item.first_observation == null ? "" : item.first_observation}</td>
                                      </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="col-md-5 mt-3">
                                <Doughnut data={Intervencion}  options = {GraficoOptions} />
                                <div className="detailed-reports-chart-legend north-america-legend">
                                  <div className="item d-flex justify-content-between mx-4 mx-xl-5 mt-3">
                                    <div className="d-flex align-items-center">
                                      <p className="mb-0">BIEN</p>
                                    </div>
                                    <p className="mb-0">{graficoIntervencion[0]}</p>
                                  </div>
                                  <div className="item d-flex justify-content-between mx-4 mx-xl-5 mt-3">
                                    <div className="d-flex align-items-center">
                                      <p className="mb-0">EN PROCESO</p>
                                    </div>
                                    <p className="mb-0">{graficoIntervencion[1]}</p>
                                  </div>
                                  <div className="item d-flex justify-content-between mx-4 mx-xl-5 mt-3">
                                    <div className="d-flex align-items-center">
                                      <p className="mb-0">MAL</p>
                                    </div>
                                    <p className="mb-0">{graficoIntervencion[2]}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="contact" title="SEGUIMIENTO">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12 col-xl-12 col-12">
                            <div className="row">
                              <div className="col-md-7">
                                <div className="table-responsive mb-3 mb-md-0">
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th className= "justify-content-center">Indicador</th>
                                        <th className= "justify-content-center" >Fecha</th>
                                        <th className= "justify-content-center" >Estado</th>
                                        <th className= "justify-content-center" >Observación</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {indicadorS.map((item,index) =>(
                                        <tr>
                                        <td>{item.indicator.name}</td>
                                        <td>{item.modification_date}</td>
                                        {item.state ? (
                                          <td>{item.state.name}</td> 
                                        ):(
                                          <td></td> 
                                        )
                                        }
                                        <td>{item.first_observation == null ? "" : item.first_observation}</td>
                                      </tr>
                                      ))}
                                    </tbody>
                                    
                                  </table>
                                </div>
                              </div>
                              <div className="col-md-5 mt-3">
                                <Doughnut data={Seguimiento}  options = {GraficoOptions} />
                                <div className="detailed-reports-chart-legend north-america-legend">
                                  <div className="item d-flex justify-content-between mx-4 mx-xl-5 mt-3">
                                    <div className="d-flex align-items-center">
                                     
                                      <p className="mb-0">BIEN</p>
                                    </div>
                                    <p className="mb-0">{graficoSeguimiento[0]}</p>
                                  </div>
                                  <div className="item d-flex justify-content-between mx-4 mx-xl-5 mt-3">
                                    <div className="d-flex align-items-center">
                                     
                                      <p className="mb-0">EN PROCESO</p>
                                    </div>
                                    <p className="mb-0">{graficoSeguimiento[1]}</p>
                                  </div>
                                  <div className="item d-flex justify-content-between mx-4 mx-xl-5 mt-3">
                                    <div className="d-flex align-items-center">
                                      
                                      <p className="mb-0">MAL</p>
                                    </div>
                                    <p className="mb-0">{graficoSeguimiento[2]}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="reporte" title="RESUMEN">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12 col-xl-12 col-12">
                            <div className="row project-list-showcase">
                              <div className="col-lg-4 col-md-4 col-sm-6 col-12 project-grid">
                                <div className="project-grid-inner">
                                  <div className="d-flex align-items-start " style = {{paddingBottom : 15}}>
                                    <div className="wrapper">
                                      <h5 className="project-title">Linea Base</h5>
                                    </div>
                                  </div>
                                  <div>
                                      <Doughnut data={LineaBase}  options = {GraficoOptions} />
                                      <div className="detailed-reports-chart-legend north-america-legend">
                                        <div className="item d-flex justify-content-between mx-4 mx-xl-5 mt-3">
                                          <div className="d-flex align-items-center">
                                            
                                            <p className="mb-0">BIEN</p>
                                          </div>
                                          <p className="mb-0">{graficoLineaBase[0]}</p>
                                        </div>
                                        <div className="item d-flex justify-content-between mx-4 mx-xl-5 mt-3">
                                          <div className="d-flex align-items-center">
                                            
                                            <p className="mb-0">EN PROCESO</p>
                                          </div>
                                          <p className="mb-0">{graficoLineaBase[1]}</p>
                                        </div>
                                        <div className="item d-flex justify-content-between mx-4 mx-xl-5 mt-3">
                                          <div className="d-flex align-items-center">
                                            
                                            <p className="mb-0">MAL</p>
                                          </div>
                                          <p className="mb-0">{graficoLineaBase[2]}</p>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-sm-6 col-12 project-grid">
                                <div className="project-grid-inner">
                                    
                                  <div className="d-flex align-items-start " style = {{paddingBottom : 15}}>
                                    <div className="wrapper">
                                      <h5 className="project-title">Intervención</h5>
                                    </div>
                                  </div>
                                  <div>
                                      <Doughnut data={Intervencion}  options = {GraficoOptions} />
                                      <div className="detailed-reports-chart-legend north-america-legend">
                                        <div className="item d-flex justify-content-between mx-4 mx-xl-5 mt-3">
                                          <div className="d-flex align-items-center">
                                           
                                            <p className="mb-0">BIEN</p>
                                          </div>
                                          <p className="mb-0">{graficoIntervencion[0]}</p>
                                        </div>
                                        <div className="item d-flex justify-content-between mx-4 mx-xl-5 mt-3">
                                          <div className="d-flex align-items-center">
                                            
                                            <p className="mb-0">EN PROCESO</p>
                                          </div>
                                          <p className="mb-0">{graficoIntervencion[1]}</p>
                                        </div>
                                        <div className="item d-flex justify-content-between mx-4 mx-xl-5 mt-3">
                                          <div className="d-flex align-items-center">
                                            
                                            <p className="mb-0">MAL</p>
                                          </div>
                                          <p className="mb-0">{graficoIntervencion[2]}</p>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-sm-6 col-12 project-grid">
                                <div className="project-grid-inner">
                                    
                                  <div className="d-flex align-items-start " style = {{paddingBottom : 15}}>
                                    <div className="wrapper">
                                      <h5 className="project-title">Seguimiento</h5>
                                    </div>
                                  </div>
                                  <div>
                                      <Doughnut data={Seguimiento}  options = {GraficoOptions} />
                                      <div className="detailed-reports-chart-legend north-america-legend">
                                        <div className="item d-flex justify-content-between mx-4 mx-xl-5 mt-3">
                                          <div className="d-flex align-items-center">
                                            
                                            <p className="mb-0">BIEN</p>
                                          </div>
                                          <p className="mb-0">{graficoSeguimiento[0]}</p>
                                        </div>
                                        <div className="item d-flex justify-content-between mx-4 mx-xl-5 mt-3">
                                          <div className="d-flex align-items-center">
                                            
                                            <p className="mb-0">EN PROCESO</p>
                                          </div>
                                          <p className="mb-0">{graficoSeguimiento[1]}</p>
                                        </div>
                                        <div className="item d-flex justify-content-between mx-4 mx-xl-5 mt-3">
                                          <div className="d-flex align-items-center">
                                            
                                            <p className="mb-0">MAL</p>
                                          </div>
                                          <p className="mb-0">{graficoSeguimiento[2]}</p>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
  
};
export default DetailLender;