import React, { useEffect, useState } from "react";
import Lender from "./prestadores/Lenders";

var FileSaver = require("file-saver");


function Home() {
  useEffect(() => {}, []);

  var detailedReports = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
  };
  
  //const mapURL = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBDaeWicvigtP9xPv919E-RNoxfvC-Hqik&callback=iniciarMap';

  return (
    <div>
      <div>
        <Lender/>
      </div>
    </div>
    
  );
}
export default Home;
