import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, Row } from "react-bootstrap";
import ListLenders from '../../Components/Lenders/ListLenders';
import LenderService from "../../services/Prestadores.API/Lenders";
import Maps from "../Maps/Maps";


var FileSaver = require('file-saver');


function Lender(){
  
  const [user, setUser] = useState({});
  const [lendertop, setLendertop] = useState([]);
  

  const getDataTop = async () => {
    try {

      const responseLendertop = await LenderService.getLendersTop();
      if(responseLendertop.ok ){
        console.log("Top de Prestadores",responseLendertop.data)
        setLendertop(responseLendertop.data);
      }
    } catch (e) {
      console.error("error al obtener la data de IdeaDetails", e.error)
    }
  }

 

  const getUser = () =>{
    const user = JSON.parse(localStorage.getItem("username"));
    setUser(user);
  }


  useEffect(()=>{
    getDataTop();
    getUser();
  
  },[])

  return (

      <div>
        <div className="row">
      <div className="col-12 col-xl-5 mb-4 mb-xl-0 grid-margin">
        <h4 className="font-weight-bold">Hola, Bienvenido!</h4>
        <h4 className="font-weight-normal mb-0">{user?.full_name}</h4>
      </div>
      <div className="col-12 col-xl-7 grid-margin">
          <div className="mb-3 text-lg-right mb-xl-0">
            <form method="get" action="/prestadores">
              <button className="btn btn-warning rounded-0 text-white " type="submit" >
                Prestadores
              </button>
            </form>
          </div>
      </div>

      <div className="col-md-3 ">
        <div className="card">
          <Link className="float-left mr-4" to={"/prestadores"}>
            <div className="card-body">
              <p className="card-title text-md-center text-xl-left text-dark" >PRESTADORES "TOTAL"</p>
              <div className="d-flex flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                <h3 className="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">
                  21
                </h3>
                <i className="ti-user icon-md text-muted mb-0 mb-md-3 mb-xl-0"></i>
              </div>
            </div>
          </Link>
        </div>
      </div>

      <div className="col-md-3 ">
        <div className="card">
          <Link className="float-left mr-4" to={"/lenderbien"}>
            <div className="card-body">
              <p className="card-title text-md-center text-xl-left text-success">ESTADO "BIEN"</p>
                <div className="d-flex flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                  <h3 className="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0 text-success">
                    15
                  </h3>
                  <i className="mdi mdi-check-all icon-md text-success mb-0 mb-md-3 mb-xl-0"></i>
                </div>
            </div>
          </Link>
        </div>
      </div>

      <div className="col-md-3 ">
        <div className="card">
          <Link className="float-left mr-4" to={"/lenderpending"}>
            <div className="card-body">
              <p className="card-title text-md-center text-xl-left text-warning">ESTADO "EN PROCESO"</p>
              <div className="d-flex flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                <h3 className="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0 text-warning">
                  4
                </h3>
                <i className="ti-layers-alt icon-md text-warning mb-0 mb-md-3 mb-xl-0"></i>
              </div>
            </div>
          </Link>
        </div>
      </div>
      
      <div className="col-md-3 ">
        <div className="card">
          <Link className="float-left mr-4" to={"/lendermal"}>
            <div className="card-body">
              <p className="card-title text-md-center text-xl-left font-weight-bold text-danger">ESTADO "MAL"</p>
              <div className="d-flex flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                <h3 className="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0 text-danger">
                  2
                </h3>
                <i className="mdi mdi-alert icon-md text-danger mb-0 mb-md-3 mb-xl-0"></i>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
    <br></br>
    <div className="row">
          <div className="col-md-4 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <p className="card-title mb-0 text-dark">TOP PRESTADORES</p>
                <div className="table-responsive">
                  <table className="table table-striped table-borderless">
                    <thead>
                      <tr>
                        <th>PRESTADOR</th>
                        <th>INDICADORES</th>
                      </tr>  
                    </thead>
                    <tbody>
                      {lendertop?.map((item,index)=>
                        <tr key={index} >
                          <td className="font-weight-bold">{item.lender__name} </td>
                          <td className="font-weight-medium text-success">{item.count} </td>
                        </tr>
                      )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 grid-margin ">
            <div className="card">
              <div className="card-body">
                <Maps/>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
  
};
export default Lender;