import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { AdminRouter } from "./AdminRouter";
import Spinner from "./Components/Shared/Spinner";
import PrivateRouter from "./PrivateRouter";

//home

const Login = lazy(() => import("./page/user-pages/Login"));
const Home = lazy(() => import("./page/Home"));
const Prestadores = lazy(() => import("./page/prestadores/Prestadores"));

//Fortaleciendo Vinculos

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRouter component={AdminRouter} path='/'/>
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
