import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, Row } from "react-bootstrap";
import ListLendersProceso from "../../Components/Lenders/ListLendersProceso";
import LenderProcesoService from "../../services/Prestadores.API/LenderProceso";


var FileSaver = require('file-saver');


function LenderProceso(){

  const [lenderProceso, setLenderProceso] = useState([]);
  
  const getData = async () => {
    try {

      const responseLenderProceso = await LenderProcesoService.getLenderProceso();
      setLenderProceso(responseLenderProceso);

    } catch (e) {
      console.error("error al obtener la data de IdeaDetails", e.error)
    }
  }

  useEffect(()=>{
    getData();
  },[])

  return (
    <div> 
      <Row>
        <Card className="col-lg-12">
          <Card.Body>
            <div className="row mb-4 justify-content-between">
                <div className = "col-lg-12 d-none d-sm-none d-md-block">
                  <Link className="float-right mr-4" to={"/home"}>
                    <i className=" mdi mdi-arrow-left-drop-circle-outline ml-2  text-info" style={{fontSize:30}}></i>
                  </Link>
                  <h4 className="card-title float-left mt-2">PRESTADORES - ESTADO "PROCESO"</h4>
                </div>
                <div className = "col-lg-8 pr-0 pl-0 col-ms-12 mr-3">
                  {/* <SearchCompromisos className = "float-left" onChangeBusqueda = {onChangeBusqueda}/> */}
                </div>
              </div>
            <ListLendersProceso
               data={lenderProceso}
              // page={paginacion.page}
              // sizePerPage={paginacion.sizePerPage}
              // totalSize={compromisosPage.Total}
              // onTableChange={handleTableChange}
              // verificacion = {0}
            />
          </Card.Body>
        </Card>
      </Row>
    </div>
  );
  
};
export default LenderProceso;
